// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './AllDashboard.css';
import { UserOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Row, Modal, Button, Form, Input, Select, DatePicker, TimePicker, AutoComplete,Collapse, Table as AntTable } from 'antd';
import CaseController from './CaseController.web';
import { caseStudy, lifestyle, medical, occupational, phychology, physiotherapy, returnWork } from '../../catalogue/src/assets';
import {Link} from 'react-router-dom';
import { noAppointment, userImage,downArrow,upArrow } from './assets';
import moment from "moment";
import { cancel, noData } from '../../tasks/src/assets';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import {view_image_img } from '../../appointmentmanagement/src/assets';
const { TextArea } = Input;
const { Panel } = Collapse;
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { styled } from "@material-ui/core/styles";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
const dropdown = <ExpandMoreOutlinedIcon />;

const AntDRow = styled("div")({
    padding:"20px 20px 30px 20px",
    width: "100%"
  })

  const AntDSelectBox = styled(Select)({
    color: "#94A3B8 !important",
    fontSize: "16px !important",
    textTransform:"capitalize",
    fontWeight: 400,
    position:"relative",
   
  })
  const AntDFormItem = styled(Form.Item)({
    "& .ant-form-item-label > label":{
      fontSize: "16px !important",
      fontWeight: 700,
    }
  })
  const Span = styled("span")({
    marginTop: "4px",
    fontSize: "14px",
    color:"#0F172A",
    fontWeight: 700,
  })

  const TableData = styled("span")({
    color: "#000",
    fontWeight: 400,
    fontSize: "12px",
    
  })
  const TableData1 = styled("span")({
    textTransform:"capitalize",
    alignItems:"center",
    color: "#000",
    display:"flex",
    fontSize: "14px",
    fontWeight: 400,
  })
  const TableData2 = styled("span")({
    display:"flex",
    alignItems:"center",
    color: "#000",
    textTransform:"capitalize",
    fontSize: "12px",
    fontWeight: 600,
  })
  const TableData3 = styled("span")({
    display:"flex",
    fontSize: "12px",
    fontWeight: 600,
    alignItems:"center",
    color: "#059669",
    textTransform:"capitalize",
  })
  const TableDesign = styled(AntTable)({
    borderBottomLeftRadius: "8px" ,
    overflow: "hidden",
    borderBottomRightRadius: "32px",
    "& .ant-table-thead .ant-table-cell": {
        background: "#F1F5F9 !important",
        borderBottom: "1px solid #E2E8F0 !important",
     
    }
  })

class DashboardDetails extends CaseController {
    formRef: any;
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}

    async componentDidMount(){
		this.getR1Tasks();
        this.getR1Appointments();
        this.getCaseById();
        this.getViewDetails();
        this.getDetailsById();
        this.getAlTermsGoals();
        this.getAssessments();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');
        localStorage.setItem("userGoalId",id)
	}
    dropdownStyle = {
		border: '1px solid #CBD5E1 ',
		borderRadius: "10px",
		padding:"0px" ,
		marging:"0px",
   
	  };
    
    assessmentColumn:any = [
		{
			title: (<Span>Assessment Name</Span>),
            ellipsis: true,
			width: 260,
			render: (event: Assessment2) => 
            (
				<TableData1>
					{this.getIcon(event.attributes.status)}
                    {event.attributes.assessment_name}
				</TableData1>
			),
		},
		{
			title: (<Span>Assigned By</Span>),
			ellipsis: true,
			render: (event: Assessment2) => (
				<TableData>
					{event.attributes.assigned_by}
				</TableData>
			)
		},
    {
			title: (<Span>Assigned Date</Span>),
			ellipsis: true,
			render: (event: Assessment2) => 
            (
				<TableData>
					{event.attributes.assigned_date}
				</TableData>
			)
		},
    {
			title: (<Span>Score</Span>),
			ellipsis: true,
			render: (event: Assessment2) => 
            (
                <TableData3>
                    {
                        event.attributes.score ? 
                        (
                        <>
                            {event.attributes.score}
                            <span style={{ color: "black", fontSize: "12px", fontWeight: 600, marginLeft: "5px" }}>
                            out of 100
                            </span>
                        </>
                        ) : 
                        (
                            <span style={{ color: "#94A3B8", fontSize: "12px", fontWeight: 600 }}>Score is pending</span>
                        )
                    }
                </TableData3>
			),
		},
    {
			title: (<Span>Period</Span>),
			ellipsis: true,
			render: (event: Assessment2) => 
            (
				<TableData2>
					{event.attributes.period}
				</TableData2>
			),
		},
    {
			title: (<Span>Status</Span>),
			ellipsis: true,
			render: (event: Assessment2) => 
            (
                this.renderStatus(event.attributes.status)
            )
		},
		{
			title: (<Span>Action</Span>),
			dataIndex: '',
			key: (event: Assessment2) => event.attributes.client_id,
			render: () => <a className='view-text' >View</a>
		},
	];
    renderAddAssessmentModal() {
        return (
            <Modal
                className="appointment-modal"
                title={
                <div className="header-container2" 
                    style={{ padding: "28px 24px 15px 26px",width:"440px"}}>
                    <h5 className="title-heading">Add Assessment</h5>
                </div>
                }
                closable={false}
                footer={null}
                open={this.state.showAddAssessment}
                okButtonProps={{
                style: {display: "none"},
                }}
                cancelButtonProps={{
                style: {display: "none"},
                }}
            >
                <Form
                    ref={this.formRef}
                    layout="vertical"
                    style={{
                        padding: "0 24px 20px 24px",
                        position: "relative" 
                    }}
                    onFinish={
                        ()=>this.saveAssessmentData(this.formRef)
                    }
                >
                    <Row gutter={12}>
                        <AntDRow>
                            <Col xs={24}>
                                <AntDFormItem
                                    className="bold-lable"
                                    name="Assessment"
                                    label="Assessment"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Select Assessment"
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Assessment"
                                        id="select-type"
                                        className="bold-lable"
                                        dropdownStyle={this.dropdownStyle}
                                        suffixIcon={dropdown}
                                        getPopupContainer={this.getPopupContainer}
                                    >
                                        <option value= "Functional-Pain" style={{color:"#94A3B8"}}>
                                            Functional Pain
                                        </option>
                                        <option value= "GAD-7-Anxiety"  style={{color:"#94A3B8"}}>
                                            GAD 7 Anxiety
                                        </option>
                                        <option value= "Pittsburgh-Sleep-Quality-Index"  style={{color:"#94A3B8"}}> 
                                            Pittsburgh Sleep Quality Index
                                        </option>
                                        <option value= "Modified-Fatigue-Impact-Scale" style={{color:"#94A3B8"}}>
                                            Modified Fatigue Impact Scale
                                        </option>
                                        <option value= "PHQ-9-Depression"  style={{color:"#94A3B8"}}>
                                            PHQ 9 Depression
                                        </option>
                                    </Select>
                                </AntDFormItem>
                            </Col>
                            <Col xs={24}>
                                <AntDFormItem
                                    name="Frequency"
                                    label="Frequency"
                                    className="bold-lable"
                                    rules={[
                                    {
                                        required: true,
                                        message: "Please Select Frequency"
                                    },
                                    ]}
                                >
                                    <AntDSelectBox
                                        className="bold-lable"
                                        dropdownStyle={this.dropdownStyle}
                                        id="select-type1"
                                        placeholder="Select Frequency"
                                        suffixIcon={dropdown}
                                        getPopupContainer={this.getPopupContainer}
                                    >
                                        <option  value= 'weekly'  style={{color:"#94A3B8"}}> Weekly</option>
                                        <option value= "monthly"  style={{color:"#94A3B8"}}> Monthly</option>
                                    </AntDSelectBox>
                                </AntDFormItem>
                            </Col>
                        </AntDRow>
                        <div className="formfooter">
                        <Col xs={24}>
                            <Form.Item className='btnContainer' >
                                <Button
                                    data-testid="hide-model1"
                                    className="cancel-btn-style"
                                    type="primary"
                                    onClick={() => {
                                        this.handleCancelAssessmentModal(this.formRef)
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submit-heading"
                                    loading={this.state.addAssessmentLoader}
                                    type="primary"
                                    htmlType="submit"
                                    style={{marginRight:"20px"}}
                                >
                                    Add
                                </Button>
                            </Form.Item>
                        </Col>
                        </div>
                    </Row>
                </Form>
            </Modal>
        );
      }
    renderStatus = (status) => {
        if (status === "reassess") return <div className='reassess-div'>Reassess</div>;
        if (status === "pending") return <div className='ongoing-div'>Pending</div>;
        if (status === "Status not updated yet") return <div className='ongoing-div'>Pending</div>;
        if (status === "completed") return <div className='completed-div'>Completed</div>;
        if (status === "approval_pending") return <div className='approval-div'>Approval Pending</div>;
        
    };
    getIcon = (data:string)=>{
        if(data === "pending"){
          return <RadioButtonUncheckedIcon style={{color:"#FBF1F9",fontSize:"12px",marginRight:"6px",height:"12px",width:"12px"}}/>
        }
        if(data === "completed"){
          return <CheckCircleIcon style={{color:"#006845",fontSize:"12px",marginRight:"6px"}}/>
        }
        else{
          return <CancelIcon style={{color:"#FF9595",fontSize:"12px",marginRight:"6px"}}/>
        }
      }
    renderEmptyData = (text: string) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                <img src={noData} alt="No Data" className='image-q' />
                <div className='text-no'>No {text} added yet</div>
            </div>
        );
    }
    renderUserCard = (role, users) => (
        users && users.length > 0 ? 
            users.map((user, index) => (
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6} key={user.id}>
                    {index === 0 ? (
                            <p className='view-title'>{role.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</p>
                        ) : (
                            <div style={{ height: '66px' }}></div>
                        )}
                    <div className='case-card'>
                        <div style={{ display: 'flex' }}>
                            <img src={userImage} style={{ marginRight: '4px', height: '24px', width: '24px' }} />
                            <p className='case-full-name'>{user.full_name.charAt(0).toUpperCase() + user.full_name.slice(1)}</p>
                        </div>
                        <div className='view-deallocate'>
                            <p onClick={() => this.showViewUserModal(role, user.id)} data-testid="view-details1" className='view-details'>View Details</p>
                            <p onClick={() => this.showDeallocateModal(role, user.id)} data-testid="view-deallocate-detail" className='view-details'>Deallocate</p>
                        </div>
                        {index === 0 && (
                            <div data-testid="add" className='add-case-div' onClick={() => this.showCreateUserModal(role)}>
                                <PlusCircleOutlined style={{ fontSize: '18px', color: '#830065', cursor: 'pointer' }} />
                                <p className='add-bodytext-1' style={{ cursor: 'pointer' }} >Add More</p>
                            </div>
                        )}
                    </div>
                </Col>
            ))
            :
            <Col xs={24} sm={12} md={8} lg={6}>
                <p className='view-title'>{role.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</p>
                <div className='case-card'>
                    <UserOutlined style={{ fontSize: '18px', color: '#94A3B8' }} />
                    <p style={{ color: '#94A3B8' }}>Not added yet</p>
                    <div style={{marginTop: '26px'}} className='add-case-div' onClick={() => this.showCreateUserModal(role)}>
                        <PlusCircleOutlined style={{ fontSize: '18px', color: '#830065' }} />
                        <p className='add-bodytext-1'>Add</p>
                    </div>
                </div>
            </Col>
    );
    changeStateNull= ()=>{
        this.setState({ activeKey: null })
    }


    genExtra(key) {
		return (
			<div id={`swtich-div${key}`} style={{ display: 'flex', gap: '20px' }}>
				{this.state.activeKey === key ?
						<img id={`up${key}`} data-testid="getextra-detail1" src={upArrow} style={{ height: "11px", width: '11px' }} onClick={this.changeStateNull} /> :
						<img id={`down${key}`} data-testid="getextra-detail2" src={downArrow} style={{ height: "11px", width: '11px', marginTop:'10px' }} onClick={() => this.setState({ activeKey: key })} />
				}
			</div>
		)
	}
    
    renderAddUserModal() {
        const autoCompleteStyle = {
            width: '100%',
            height: '30px',
            borderRadius: '4px'
        };
		return (
			<Modal
				className="appointment-modal"
				id="user-modal"
				title={<div class="header-container1" style={{ padding: "36px 24px 15px" }}>
					<h2 style={{ fontSize: '24px', color: '#000000', fontWeight: 700 }}>
                    Add {this.state.currentRoleType.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                    </h2>
					<img class="image" onClick={() => {
						this.formRef.current.resetFields()
						this.handleCancelUserModal()
					}} style={{ height: '22px', width: '22px', color: '#334155' }} src={cancel} />
				</div>}
				open={this.state.showAddUserModal}
				closable={false}
				footer={null}
                width={600}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
			>
				<Form
					data-testid="save-data"
					ref={this.formRef}
					layout="vertical"
					className="form"
					style={{ padding: "0 24px 20px 24px", position: "relative" }}
					onFinish={() => {
						this.onCreateUser(this.formRef.current.getFieldsValue());
                        this.formRef.current.resetFields()
					}}

				>
					<Row gutter={12}>
                    <Col xs={24}>
                        <Form.Item
                            label={<span>Full Name</span>}
                            name="full_name"
                            className="bold-label"
                            validateFirst
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >

                            <AutoComplete
                                className="font-style-auto"
                                style={autoCompleteStyle}
                                onChange={(value, option) => {
                                    if (option && option.key) {
                                        const selectedUser = option.key;
                                        const email = selectedUser.split('(')[1]?.replace(')', ''); 
                                        this.formRef.current.setFieldsValue({ email });
                                    }

                                }}

                                filterOption={(inputValue, option) =>
                                    option.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }

                            >

                                {this.state.userList.map((user, index) => (

                                    <AutoComplete.Option key={user} value={user}>

                                        {user}

                                    </AutoComplete.Option>

                                ))}

                            </AutoComplete>

                        </Form.Item>

                        </Col>

                        <Col xs={24}>

                        <Form.Item label={<span className="bold-label">Email ID</span>} name="email" className="bold-label" validateFirst>

                            <Input className="font-style-auto" />

                        </Form.Item>

                        </Col>
						<Col xs={24}>
                        <div style={{ width: '100%', height: '1px', backgroundColor: '#E0E0E0', margin: '20px 0' }}></div>
                        <div style={{display: 'flex', justifyContent: 'end', marginTop: '20px'}}>
								<Button
									data-testid="hide-modal"
									type="primary"
									onClick={() => {
										this.handleCancelUserModal()
										this.formRef.current.resetFields()
									}}
									style={{
										background: "#ffff",
										borderColor: "#ffff",
										height: "56px",
										width: '120px',
										color: "#830065",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									Cancel
								</Button>
								<Button
									loading={this.state.loadingCreateUser}
									htmlType="submit"
									type="primary"
									style={{
										background: "#830065",
										borderColor: "#830065",
										borderRadius: "8px",
										height: "56px",
										width: '120px',
										color: "white",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									Add
								</Button>
						</div>
						</Col>
                        {this.state.createUserErrorMsg !== '' ?
								<p data-test-id="error-msg" style={{ color: '#DC2626', textAlign: 'left', marginTop: '5px' }}>{
									this.state.createUserErrorMsg}
								</p> : null}
					</Row>
				</Form>

			</Modal>
		);
	}

    renderGoalsTable = (goals) => {
        if (goals && goals.length === 0) {
            return <p className='no-medium-goal-title'>You have no goals added yet!</p>;
        }
        return (
            <TableContainer component={Paper} style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Table id="goal-table" aria-label="simple table" style={{ borderRadius: '0 0 32px 8px', minWidth: '800px' }} data-test-id="table">
                <TableHead style={{ backgroundColor: '#F1F5F9' }}>
                    <TableRow>
                    <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '20%', overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis', maxWidth: '10ch' }}>Name</TableCell>
                    <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned by</TableCell>
                    <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned on</TableCell>
                    <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {goals && goals.map((goal, i, arr) => (
                    <TableRow key={i} className={i === arr.length - 1 ? 'last-row' : ''}>
                        <TableCell style={{ fontSize: '14px', fontWeight: 500, color: '#334155', width: '20%', overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis', maxWidth: '10ch' }}>{goal.name}</TableCell>
                        <TableCell 
                        style={{ 
                            fontWeight: 500, 
                            color: "#334155",
                            backgroundColor: "white",
                            width: '14%', 
                            fontSize: 14}}>{goal.updated_by}</TableCell>
                        <TableCell style={{ fontSize: '14px', fontWeight: 500, color: '#334155', width: '15%' }}>{moment(goal.updated_at).format('D MMM YYYY')}</TableCell>
                        <TableCell style={{ fontSize: '14px', fontWeight: 500, color: '#334155', width: '15%' }}>
                        {this.renderStatus(goal.client_status)}
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        );
      }
      
    renderViewDetailsModal() {
		return (
			<Modal
				className="appointment-modal"
				id="view-modal"
				title={<div class="header-container1" style={{ padding: "36px 37px 15px" }}>
					<h2 style={{ fontSize: '24px', color: '#000000', fontWeight: 700 }}>
                    View {this.state.currentRoleType.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                    </h2>
					<img data-testid="cancel-modal" class="image" onClick={() => {
						this.handleCancelViewModal()
					}} style={{ height: '22px', width: '22px', color: '#334155' }} src={cancel} />
				</div>}
				open={this.state.showViewUserModal}
				closable={false}
				footer={null}
                width={600}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
			>
				<div style={{padding: '46px'}}>
                    <p className='view-title'>Full Name</p>
                    <p className='name-2'>{this.state.fullName}</p>
                    <p className='view-title'>Email ID</p>
                    <p className='name-2'>{this.state.email}</p>
                </div>
               
                       
			</Modal>
		);
	}
    renderViewClientModal() {
		return (
			<Modal
				id="view-client"
                className="appointment-modal"
				title={
                <div class="header-container1" 
                    style={{ padding: "36px 37px 15px" }}
                >
					<h2 style={{ 
                        fontSize: '24px', 
                        color: '#000000', 
                        fontWeight: 700 }}> 
                        View Client
                    </h2>
					<img 
                        class="image"  
                        data-testid="cancel-modal" 
                        onClick={() => {
						this.handleClientModal()
					    }} 
                        src={cancel} 
                        style={{
                            color: '#334155', 
                            height: '22px', 
                            width: '22px'
                        }} 
                        />
				</div>}
				open={this.state.showClientModal}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
                width={600}
                closable={false}
				footer={null}
			>
				<div style={{padding: '0 46px 10px 46px'}}>
                    <p className='view-title'>
                        Full Name
                    </p>
                    <p className='name-2'>
                        {this.state.fullName}
                    </p>
                    <p className='view-title'>
                        Email ID
                    </p>
                    <p className='name-2'>
                        {this.state.email}
                    </p>
                </div>
               
			</Modal>
		);
	}
    renderModalsDeallocate() {
		return (
			<Modal
			  className="delete-modal"
			  id="deallocate-modal"
			  open={this.state.deallocateUserModal}
			  onCancel={this.handleDeallocateModal}
			  footer={null}
			>
			<>
			  <div style={{
				padding: '50px 25px 20px 25px', fontSize: '16px',
				marginTop: '20px',fontWeight: 'bold'
			  }}>
				Are you sure you want to deallocate {this.state.currentRoleType.replace(/_/g, ' ')} {this.state.fullName}?
			  </div>
			  <div style={{ display: "flex",
			   justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{
					  height: "56px",width: "120px",
					  background: "#ffff",
					  borderColor: "#ffff",
					  borderRadius: "8px",color: "#830065",
					  fontSize: '16px',
					  fontWeight: '700'
					}}
					onClick={()=>this.handleDeallocateModal()} >Cancel</Button>
				<Button
                    loading={this.state.deallocateLoader}
					data-testid="delete-app"
					style={{
					  height: "56px",
					  width: "120px",background: "#830065",
					  borderColor: "#830065",
					  borderRadius: "8px",color: "white",
					  fontSize: '16px',
					  fontWeight: '700'}}
					onClick={() => this.deallocateUser()} >Okay</Button>
				</div>
			</>
			</Modal>
		);
	}

    renderAddTaskPopup() {
		return (
			<Modal
				className="appointment-modal"
				id="task-modal"
				title={
                <div class="header-container1" 
                    style={{ padding: "36px 24px 15px" }}
                >
					<h2 style={{ fontSize: '24px', color: '#000000', fontWeight: 700 }}>Add Task</h2>
					<img 
                        class="image" 
                        onClick={() => {
						this.formRef.current.resetFields()
						this.closeAddTaskModal()
					}} 
                    style={{ 
                        height: '22px', 
                        width: '22px', 
                        color: '#334155' 
                    }} src={cancel} />
				</div>}
                    open={this.state.showAddTaskPopup}
                    width={442}
                    closable={false}
                    footer={null}
                    cancelButtonProps={{
                        style: {
                            display: "none",
                        },
                    }}
                    okButtonProps={{
                        style: {
                            display: "none",
                        },
                    }}
				
			        >
				<Form
                    ref={this.formRef}
                    className="form"
                    layout="vertical"
					data-testid="save-data-task"
					style={{ 
                        padding: "0 24px 20px 24px", 
                        position: "relative" }}
					onFinish={() => {
						this.onFinishAddTask(this.formRef.current.getFieldsValue());
					}}

				>
					<Row gutter={12}>
						<Col xs={24}>
							<Form.Item
								label=
                                {
                                    <span className="bold-label">
                                      <span>Task Name</span> 
                                      <span className="sub-text"> (max 20 characters)</span>
                                    </span>
                                }
                                validateFirst
								name="title"
                                className="bold-label"
								rules=
                                {[
									{
										required: true,
										message: "Please enter name",
									},
									{  max: 20, min: 3, }
								]}

							>
								<Input className="font-style"  maxLength={20} placeholder='Enter name of the task' />
							</Form.Item>
						</Col>
                        <Col xs={24}>
							<Form.Item
                                name="priority"
                                label="Time Period"
                                className="bold-label"
								rules={[
									{
										required: true,
										message: "Please select time period"
									},
								]}
							>

								<Select
                                    className="font-style apt-design"
                                    placeholder="Enter time period"
                                    id="select-type"
									options=
                                        {[
                                            { value: 'daily', label: 'Daily' },
                                            { value: 'weekly', label: 'Weekly' },
                                            { value: 'monthly', label: 'Monthly'}
                                        ]}
									
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
                            <Form.Item
                                name="from_date"
                                className="bold-label"
                                label="From Date"
                                rules=
                                {[
                                    {
                                        required: true,
                                        message: "Please select from date"
                                    }
                                ]}

                            >
                                <DatePicker
                                    id="date"
                                    format="DD MMM YYYY"
                                    picker="date"
                                    className="font-style time-design"
                                    placeholder='Date of Entry'
                                    inputReadOnly={true}
                                />
                            </Form.Item>
						</Col>
                        <Col xs={24}>
                            <Form.Item
                                className="bold-label"
                                label="To Date"
                                name="due_date"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select to date"
                                    }
                                ]}

                            >

                                <DatePicker
                                    className="font-style time-design"
                                    format="DD MMM YYYY"
                                    placeholder='Date of Entry'
                                    id="date"
                                    picker="date"
                                    inputReadOnly={true}
                                />
                            </Form.Item>
						</Col>
						<Col xs={24}>
                        <div style={{ width: '100%', margin: '20px 0', height: '1px', backgroundColor: '#E0E0E0', }}></div>
                        <div style={{display: 'flex', marginTop: '20px', justifyContent: 'end', }}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.closeAddTaskModal()
                                    this.formRef.current.resetFields()
                                }}
                                data-testid="hide-task-modal"
                                style={{
                                    borderColor: "#ffff",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    height: "56px",
                                    width: '120px',
                                    color: "#830065",
                                    background: "#ffff"
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                loading={this.state.loadingAddTask}
                                htmlType="submit"
                                style={{
                                    borderColor: "#830065",
                                    borderRadius: "8px",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    height: "56px",
                                    width: '120px',
                                    color: "white",
                                    background: "#830065"
                                }}
                            >Add</Button>
							</div>
						</Col>
                        {this.state.addTaskErrorMsg !== '' ?
                            <p data-test-id="error-msg" style={{ color: '#DC2626', textAlign: 'left' }}>{
                                this.state.addTaskErrorMsg}
                            </p> : null
                        }
					</Row>
				</Form>

			</Modal>
		);
	}

    renderAddAppointmentModals() {
		return (
			<Modal
                id="appointment-modal"
				className="appointment-modal"
				title={
                <div class="header-container1" 
                    style={{ padding: "20px 24px 15px 24px" }}>
					<h5 className="APP-title-heading" 
                    style={{ 
                        fontSize: '14px',
                        color: '#64748B',
                        fontWeight: 'bold' }}>
                        Book Appointment
                    </h5>
					<img class="image" onClick={() => {
						this.formRef.current.resetFields()
						this.hideModel()
					}} 
                    style={{ 
                        height: '24px',
					    width: '24px' }}
					    src={cancel} />
				</div>}
                closable={false}
				footer={null}
				open={this.state.showAddModel}
				okButtonProps={{
					style: {
						display: "none", 
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none", 
					},
				}}
			>
				<Form
					data-testid="save-data-appointment"
					ref={this.formRef}
					layout="vertical"
					className="form"
					style={{ 
                        padding: "0 24px 20px 24px",
					    position: "relative" 
                    }}
					onFinish={() => {
						this.saveData(this.formRef.current.getFieldsValue());
						this.formRef.current.resetFields();
					}}

				>
					<Row gutter={12}>
						<Col xs={24}>
							<Form.Item
                                className="bold-label"
                                validateFirst
								label="Name of The Person"
								name="name"
								rules={[
									{
										required: true,
										message: "Please enter name",
									},
									{ min: 3 },
									{
										validator: this.customNameValidator,
									
									}
								]}

							>
								<Input 
                                    maxLength={30} 
                                    className="font-style" 
                                    placeholder='Enter name' />
							</Form.Item>
						</Col>
						<Col xs={24}>
						
							<div>
								<div style={{ fontSize: "15px", padding: "10px 0",  fontWeight: "700", color: "#334155" }}>Date</div>
								<DatePicker
									id="date"
                                    format="DD MMM YYYY"
									picker="date"
									inputReadOnly={true}
									key={this.state.dateValue}
									data-testid="StartDatePicker"
									className="font-style time-design"
									disabledDate={this.handledatepicker}
									allowClear={false}
									value={this.state.dateValue ? moment(this.state.dateValue, "DD MMM YYYY") : null}
                                    onChange={( dateString) => this.setState({ dateValue: dateString, dateError: null })}
									style={{ border: this.state.dateError !== null && "1px solid red" }}
									getPopupContainer={this.handlePopupContainer}
								/>

							</div>
							{this.state.dateError !== "" ?
								<p data-test-id="error-msg" 
                                    style={{
                                        color: '#DC2626',
                                        textAlign: 'left',
                                        marginTop: "1px",
                                        fontWeight:"400",
                                        fontSize: "12px"
								    }}>{this.state.dateError}
								</p> : null}
							{this.state.errorMsg !== '' ?
								<p data-test-id="error-msg" 
                                    style={{
                                        color: '#DC2626',
                                        textAlign: 'left',
                                        marginTop: "8px"
								    }}>{this.state.errorMsg}
								</p> : null}
						</Col>
						<Col xs={24}>
							<Form.Item
                                name="starttime"
                                className="bold-label"
								label="Start Time"
								rules={[
									{
										required: true,
										message: "Please select start time"
									}
								]}
							>

								<TimePicker
                                    format={'HH:mm'}
                                    defaultOpenValue={moment('00:00', 'HH:mm')}
									className="font-style time-design"
									id="start-time"
									inputReadOnly={true}
                                    showNow={false}
									placeholder={"Set Time Slot"}
									getPopupContainer={this.handlePopupContainer}
									allowClear={false}
								/>
							</Form.Item>
						</Col>

						<Col xs={24}>
							<Form.Item
                                dependencies={["starttime"]}
                                className="bold-label"
                                disabled={true}
                                validateFirst
								label="End Time"
								name="endtime"
								rules={[
									{
										required: true,
										message: "Please select end time"
									}
								]}
							>

								<TimePicker
                                    inputReadOnly={true}
                                    allowClear={false}
                                    showNow={false}
									className="font-style time-design"
									format={'HH:mm'}
									defaultOpenValue={moment('00:00', 'HH:mm')}
									id="end-time"
                                    onChange={() => this.setState({ errorMsg: '' })}
									getPopupContainer={this.handlePopupContainer}
									placeholder={"Set Time Slot"}
								/>

							</Form.Item>
							{this.state.errorMsg !== '' || "Availability date can't be blank" ?
								<p data-test-id="error-msg" 
                                style={{ 
									color: '#DC2626',
								    textAlign: 'left'
								 }}>{this.state.errorMsg}
								</p> : null}
						</Col>
						<Col xs={24}>
							<Form.Item
                                label="Title"
                                className="bold-label"
								name="title"
								rules={[
									{
										required: true,
										message: "Please enter title"
									},
									{ min: 4 }
								]}

							>

								<Input maxLength={64} className="font-style" placeholder='Enter title' />

							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
                                label="Type of Appointment"
                                className="bold-label"
								name="type"
								rules={[
									{
										required: true,
										message: "Please select tye of appointment"
									}
								]}

							>

								<Select
									className="font-style apt-design"
                                    placeholder="Select Type of Appointment"
									options={[
										{ value: 'in_person', label: 'In Person' },
										{ value: 'online', label: 'Online' },
									]}
									id="select-type"
									getPopupContainer={this.handlePopupContainer}
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
							<Form.Item
                                label="Location"
                                className="bold-label"
								name="location"
								rules={[
									{
										required: true,
										message: "Please enter location"
									},
								]}

							>
								<Input
								 maxLength={100}
								  className="font-style"
								   placeholder='Enter location'
								/>
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
                                label="Purpose (Optional)"
                                className="bold-label"
								name="purpose"
							>
								<TextArea 
                                    maxLength={150} c
                                    lassName="font-style" 
                                    style={{
                                        resize: "none"
                                        }} 
                                    rows={4} 
                                    placeholder="Enter purpose" />
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
                                name="information"
								label="Additional Information (Optional)"
								className="bold-label"
							>
								<Input maxLength={200} className="font-style" placeholder='Enter Additional Information' />
							</Form.Item>
						
						</Col>
						<Col xs={24}>
							<Form.Item>
								<Button
                                    type="primary"
									className="App-cancel-btn-style"
									data-testid="hide-appointment-modal"
									onClick={() => {
										this.hideModel()
										this.formRef.current.resetFields()
									}}
								>
									Cancel
								</Button>
								<Button
                                    htmlType="submit"
                                    type="primary"
									className="App-submit-heading"
									loading={this.state.loadingData}
									style={{
										height: "32px", 
                                        width: '108px', 
                                        color: "white",
										background: "#830065", 
                                        borderColor: "#830065",
										borderRadius: "8px",
										fontWeight: "bold", 
                                        fontSize: "14px",
									}}
									onClick={this.checkDate}
								>
									Save
								</Button>
							</Form.Item>
						</Col>

					</Row>
				</Form>

			</Modal>
		);
	}

    renderEditStatusModal() {
		return (
			<Modal
				className="appointment-modal"
				id="edit-status-modal"
				title={
                <div class="header-container1" 
                style={{ padding: "36px 24px 15px" }}>
					<h2 style={{ fontSize: '24px', color: '#000000', fontWeight: 700 }}>
                    Edit
                    </h2>
					<img class="image" onClick={() => {
						this.formRef.current.resetFields()
						this.cancelEditStatusModal()
					}} style={{ height: '22px', width: '22px', color: '#334155' }} src={cancel} />
				</div>}
				open={this.state.editStatusPopup}
                width={600}
                closable={false}
				footer={null}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
			>
				<Form
                    ref={this.formRef}
                    layout="vertical"
                    data-testid="save-status"
					className="form"
					style={{ padding: "0 24px 20px 24px", position: "relative" }}
					onFinish={() => {
						this.editStatus(this.formRef.current.getFieldsValue());
					}}

				>
					<Row gutter={12}>
                        <Col xs={24}>
							<Form.Item
								name="status"
								label="Case Status"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please select status"
									},
								]}

							>

								<Select
									className="font-style apt-design"
									id="select-type"
									placeholder="Select Status"
									options={[
										{ value: 'open', label: 'Open' },
										{ value: 'closed', label: 'Closed' }
									]}
									
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
                        <div style={{ width: '100%', height: '1px', backgroundColor: '#E0E0E0', margin: '20px 0' }}></div>
                        <div style={{display: 'flex', justifyContent: 'end', marginTop: '20px'}}>
								<Button
									data-testid="cancel-status-model"
									type="primary"
									onClick={() => {
										this.cancelEditStatusModal()
										this.formRef.current.resetFields()
									}}
									style={{
										background: "#ffff",
										borderColor: "#ffff",
										height: "56px",
										width: '120px',
										color: "#830065",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									Cancel
								</Button>
								<Button
									loading={this.state.loadingEditStatus}
									htmlType="submit"
									type="primary"
									style={{
										background: "#830065",
										borderColor: "#830065",
										borderRadius: "8px",
										height: "56px",
										width: '120px',
										color: "white",
										fontWeight: "bold",
										fontSize: "14px",
									}}
								>
									Save
								</Button>
							</div>
						</Col>
					</Row>
				</Form>

			</Modal>
		);
	}
    renderCaseStatus = (status) => {

        const formattedStatus = status?.charAt(0).toUpperCase() + status?.slice(1);

        const containerClass = status === 'open' ? 'status-container' : 'close-container';



        return (

            <div className={containerClass}>

                {formattedStatus}

            </div>

        );

    };

	render(): React.ReactNode {
		return (
            <PageWrapper title="Case View" hasBack>
                <div className='case-view-role-div' data-test-id='dashboard-details'>
                    <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                    <div style={{ width: '100%' }}>
                            <p className='view-title'>Client</p>
                            <div className='case-card'>
                                <div style={{ display: 'flex' }}>
                                    <img src={userImage} style={{ marginRight: '4px', height: '24px', width: '24px' }} />
                                    <p className='case-full-name'>{this.getUrlParameter("name")}</p>
                                </div>
                                <div className='view-deallocate'>
                                    <p data-testid="view-details" onClick={() => this.showClientModal()} className='view-details'>View Details</p>
                                    <p className='view-details'>Deallocate</p>
                                    </div>
                                   
                                </div>
                            </div> 
                        </Col>
                        {this.renderUserCard('case_manager', this.state.case_manager)}
                        {this.renderUserCard('assistant_case_manager', this.state.assistant_case_manager)}
                        {this.renderUserCard('solicitor', this.state.solicitor)}
                        {this.renderUserCard('guest', this.state.guest)}
                        </Row>
                </div>
                <div className='case-info-div'>
                    <p className='up-appoint-text'>Case number</p>
                    <p className='case-number'>{this.state.unique_reference_number}</p>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <p className='created-title'>Client Name</p>
                            <p className='date-title'>{this.getUrlParameter("name")}</p>
                        </Col>
                        <Col span={8}>
                            <p className='created-title'>Created On</p>
                            <p className='date-title'>{moment(this.state.caseAssigned).format('D MMM YYYY')}</p>
                        </Col>
                        <Col span={8}>
                            <p className='created-title'>Status</p>
                            <div className="status-edit-container">
                                <div>{this.renderCaseStatus(this.state.caseStatus)}</div>
                                <p data-testid="edit-status" onClick={() => this.showEditStatusModal()} className='add-bodytext-1 edit-button' style={{cursor: 'pointer'}}>Edit</p>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div style={{background: 'white', marginTop: '20px', padding: '5px'}}>
                    <p style={{marginLeft: '12px', marginTop: '12px'}} className="task-title-text">Goals</p>
                    <div style={{ width: '99%', height: '1px', backgroundColor: '#E0E0E0', marginLeft: '15px' ,marginTop:'14px'}}></div>
                    {this.state.goalData?.incompleted_goals?.long_term?.length === 0 ? (
                    <p className='no-long-goal-title'>You have no goals added yet!</p>
                ) : (
                    <Collapse
                    className='collapse-2'
                        id="collapse"
                        activeKey={this.state.activeKey}
                        expandIconPosition="end"
                        onChange={(e) => {
                        this.setState({ activeKey: e[e.length - 1] });
                        }}
                    >
                    {this.state.goalData?.incompleted_goals?.long_term?.map((longTermGoal: any) => (
                    <Panel
                        showArrow={false}
                        data-test-id= "panel"
                        header={
                        <div style={{display: 'flex', alignItems: 'center', marginTop:'-10px'}}>
                            <p className='long-goal-title'>{longTermGoal.name}</p>
                            <div>{this.renderStatus("pending")}</div>
                        </div>
                        }
                        key={longTermGoal.id}
                        extra={this.genExtra(longTermGoal.id)}
                    >
                        <div>
                            <p className="task-status-title">Short Term Goals</p>
                            {this.renderGoalsTable(this.state.goalData?.incompleted_goals?.short_term?.slice(0, 4))}
                            <p style={{ marginTop: '20px' }} className="task-status-title">Medium Term Goals</p>
                            {this.renderGoalsTable(this.state.goalData?.incompleted_goals?.medium_term?.slice(0, 4))}
                        </div>
                    </Panel>
                    ))}
                </Collapse>
                )}
                <div style={{ width: '99%', height: '1px', backgroundColor: '#E0E0E0', marginLeft: '15px' ,marginTop:'0px'}}></div>
               <p style={{padding: '18px 18px 0 18px',cursor:"pointer"}} className="v-all-task" onClick={this.navigatetoGoal}>View All</p>
                </div>
                <div className='appoint-dashboard-content'>
                <div className="task-header">
                    <p className="up-appoint-text">Upcoming Appoinments</p>
                    <span data-testid="add-appointment" class="add-appointment-container"
                        onClick={() => this.showModel()}>
                        <img className="a-add-icon" src={view_image_img} />
                        <h1 className="header-item3">Add Appointment</h1>
					</span>
                </div>
                
                
                {this.state.appointmentsR1Data?.length > 0 ? (
                    <div className="appointments-list">
                    <Row gutter={[16, 16]}>
                        {this.state.appointmentsR1Data?.slice(0, 2).map((e: any, index: number) => (
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} key={index}>
                                <div id="up-card" className="up-appoint-card-2" style={{ padding: '10px', width: '100%'}}>
                                    <p className="appoint-title" style={{ margin: '0 0 5px 0'}}>{e.title}</p>
                                    <p className="appoint-name" style={{ margin: '0 0 10px 0'}}>{e.name}</p>
                                    <span style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <p className="appoint-date" style={{ margin: 0}}>{moment(e.availability_date, 'DD/MM/YYYY').format('D MMM')}</p>
                                        <span style={{ margin: '0 5px' }}>|</span>
                                        <p className="appoint-time" style={{ margin: 0}}>{e.start_time} - {e.end_time}</p>
                                    </span>
                                    <p className="role-name" style={{ margin: 0 }}>Added By: {localStorage.getItem('fullName')}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                   
                </div>
                ) : (
                    <div className='appoint-dashboard-content'>
                    <p className="upcoming-appoint-text">Upcoming Appointments</p>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                            <img src={noAppointment} alt="No Upcoming Appointments" style={{ height: '48px', width: '48px' }} />
                            <p>No Upcoming Appointments</p>
                        </div>
                    </div>
                )}
                <p style={{cursor:'pointer'}} data-test-id="getCaseDetailsById" onClick={()=>this.getCaseDetailsById()}> <p className="v-all-task">View All</p></p>
                </div>
               
               
                <div className='appoint-dashboard-content'>
                    <div className="task-header">
                        <p style={{margin: 0}} className="task-title-text">Tasks</p>
                        <span data-testid="add-task" class="add-appointment-container"
                            onClick={() => this.showAddTaskPopup()}>
                            <img className="a-add-icon" src={view_image_img} />
                            <h1 className="header-item3">Add Task</h1>
					    </span>
                    </div>
                    {this.state.taskR1Data && this.state.taskR1Data.length > 0 ? (
                        <TableContainer component={Paper} style={{ maxWidth: '100%', overflowX: 'auto' }}>
                            <Table id="table" aria-label="simple table" style={{ borderRadius: '0 0 32px 8px', overflow: 'hidden' }} data-test-id="table">
                                <TableHead style={{ backgroundColor: '#F1F5F9' }}>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '20%', overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis', maxWidth: '10ch' }}>Task Name</TableCell>
                                        <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned on</TableCell>
                                        <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned Till</TableCell>
                                        <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Status</TableCell>
                                        <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.taskR1Data?.slice(0,5).map((e, i, arr) => (
                                        <TableRow key={i} className={i === arr.length - 1 ? 'last-row' : ''}>
                                            <TableCell 
                                            style={{ backgroundColor: "white", width: '20%', fontSize: 14, fontWeight: 400, color: "#0F172A",maxWidth: '11ch',
                                            overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                                        }}>{(e?.title ?? '').charAt(0).toUpperCase() + (e?.title ?? '').slice(1)}</TableCell>
                                            <TableCell 
                                                style={{ 
                                                    fontWeight: 400, 
                                                    color: "#0F172A",
                                                    backgroundColor: "white",
                                                    width: '14%', 
                                                    fontSize: 14}}>
                                                        {moment(e.from_date).format('D MMM YYYY')}
                                            </TableCell>
                                            <TableCell 
                                                style={{ fontSize: 14,backgroundColor: "white", color: "#0F172A", width: '16%', fontWeight: 400, }}>
                                                {moment(e.due_date).format('D MMM YYYY')}
                                            </TableCell>
                                            <TableCell 
                                                style={{ width: '15%', backgroundColor: "white" }}>{this.renderStatus(e.status)}
                                            </TableCell>
                                            <TableCell>
                                                <span data-testid="tablecolumnID" id="view-task" className='view-text'>
                                                    <Link to={`/task-details-view?id=${e.id}&name=${e.client_fullname?.split(" ")[0]?.replace(/^./, str => str.toUpperCase()) || "No Name"}&date=${moment(new Date()).format('yyyy-MM-DD')}`}>
                                                        <p id="view-task">View</p>
                                                    </Link>
                                                </span>
                                            </TableCell>
                                        </TableRow> ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        ) :
                    this.renderEmptyData("task")}
                <Link to={`/view-all-task?id=${this.getUrlParameter("id")}`}><p className="v-all-task">View All</p></Link>
                </div>
                <div className='appoint-dashboard-content'>
                <div className="task-header">
                        <p className="task-title-text">Assessment Test</p>
                        <span data-testid="add-assessment" class="add-appointment-container"
                            onClick={() => this.showAddAssessmentModal()}>
                            <img className="a-add-icon" src={view_image_img} />
                            <h1 className="header-item3">Add Assessment</h1>
					    </span>
                    </div>
                {this.state.assessmentData.length > 0 ?
                    <TableDesign
                        data-testid="AssessmentTable"
                        columns={this.assessmentColumn}
                        bordered
                        dataSource={this.getDataSource()}
                        pagination={false}
                        scroll={{x: 1600}}
                    />
                :
                this.renderEmptyData("assessment")
                }
                <p style={{marginTop: '22px'}} className="v-all-task">View All</p>
            </div>
				<div id='library-id' className='user-library-dashboard-content-2'>
                <p style={{marginBottom: '14px'}} className='up-appoint-text'>User Library</p>
                <Row gutter={[16,16]}>
                    {cards.map((card, i) => (
                        <Col key={i}  md={6} lg={6} xl={4} xs={24} sm={12}>
                            <Link to={`${card.link}?id=${this.getUrlParameter("case_id")}&folderType=${card.folderType}`} data-test-id = {card.folderType}>
                                <div className="user-library-card" >
                                    <img src={card.imageUrl} className="user-library-icon"  />
                                    <p className="user-library-card-label" id="folder_text" >{card.label}</p>
                                </div>
                            </Link>
                        </Col>
                    ))}
                </Row>
				</div>
                
                {this.renderAddUserModal()}
                {this.renderViewDetailsModal()}
                {this.renderModalsDeallocate()}
                {this.renderViewClientModal()}
                {this.renderAddTaskPopup()}
                {this.renderAddAppointmentModals()}
                {this.renderEditStatusModal()}
                {this.renderAddAssessmentModal()}
            </PageWrapper>
		);
	}
}


const cards = [
    {
        label: "Physiotherapy",
        imageUrl: physiotherapy,
        link: '/user-library-folder',
        folderType: 'physiotherapy'
    },
    {
        label: "Occupational Therapy",
        imageUrl: occupational,
        link: '/user-library-folder',
        folderType: 'occupational_therapy'
    },
    {
        label: "Return to Work",
        imageUrl: returnWork,
        link: '/user-library-folder',
        folderType: 'return_to_work',
    },
    {
        label: "Psychology",
        imageUrl: phychology,
        link: '/user-library-folder',
        folderType: 'psychology'
        
    },
    {
        label: "Other Medical",
        imageUrl: medical,
        link: '/user-library-folder',
        folderType: 'other_medical'
        
    },
    {
        label: "Lifestyle",
        imageUrl: lifestyle,
        link: '/user-library-folder',
        folderType: 'lifestyle'
        
    },
    {
        label: "Case management",
        imageUrl: caseStudy,
        link: '/user-library-folder',
        folderType: 'case_management'
    }
]
export default DashboardDetails;
// Customizable Area End