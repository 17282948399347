// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import UserLibraryController from './UserLibraryController.web';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from '@ant-design/icons';
import { deleteFile, download, noData } from './assets';
import moment from 'moment';
import _ from "lodash";
import { Modal, Button } from 'antd';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

class ClientRehabilitation extends UserLibraryController {
	constructor(props: any) {
		super(props);
	}
	async componentDidMount(){
        this.getFiles()
      }
      renderModalsDelete() {
		return (
			<Modal
			  className="delete-modal"
			  id="delete-modal"
			  open={this.state.showDeleteModel}
			  onCancel={this.hideDeleteModel}
			  footer={null}
			>
			<>
			  <div className="modal-div">
				Are you sure you want to delete "{this.state.fileName}" from entries?
			  </div>
			  <div className="modal-button-div" >
				  <Button
				 	data-testid="hide-app"
					style={{
					  height: "56px",
                      width: "120px",
					  background: "#ffffff",
					  borderColor: "#ffffff",
					  borderRadius: "8px",
                      color: "#830065",
					  fontSize: '16px',
					  fontWeight: '700'
					}}
					onClick={()=>this.hideDeleteModel()} >Cancel</Button>
				<Button
                    loading={this.state.deleteFileLoader}
					data-testid="delete-app"
					style={{
					  height: "56px",
					  width: "120px",
                      background: "#830065",
					  borderColor: "#830065",
					  borderRadius: "8px",
                      color: "white",
					  fontSize: '16px',
					  fontWeight: '700'}}
					onClick={() => this.deleteFile(this.state.fileID)} >Okay</Button>
				</div>
			</>
			</Modal>
		);
	}
	render(): React.ReactNode {
		return (
			<PageWrapper title={_.capitalize(this.getUrlParameter("folderType")).replaceAll("_", " ")} hasBack>
                    <div className='create-entry'>
                        <Link to={`/create-entry?clientId=${this.getUrlParameter('clientId')}&folderType=${this.getUrlParameter('folderType')}`}>
                            <div className='centered-content2'>
                                <PlusCircleOutlined className='add-icon2' />
                                <p className='bodytext-12' id="create">Add Entry</p>
                            </div>
                        </Link>
                    </div>
				<div id='client-rehab-list' className='case-management-content'>
					<div className='entry-div'>
						<p data-test-id="title" className='case-title'>Entries</p>
					</div>
					
                    <TableContainer component={Paper} style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Table aria-label="simple table" style={{ minWidth: '800px' }}>
                            <TableHead style={{ backgroundColor: '#F1F5F9' }}>
                            <TableRow>
                                <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '35%', textAlign: 'left' }}>Name</TableCell>
                                <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%', textAlign: 'left' }}>Added on</TableCell>
                                <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '25%', textAlign: 'left' }}>Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.fileData && this.state.fileData.length > 0 ? (
                                this.state.fileData.filter( (e: any) => e.attributes.title !== null).map((row: any, i: any) => (
                                    <TableRow key={i} data-test-id={`tableRow${i}`} className={i === this.state.fileData?.length - 1 ? 'last-row' : ''}>
                                        <TableCell component="th" scope="row" style={{width: '35%', textAlign: 'left' }}>
                                            {row.attributes.title}
                                        </TableCell>
                                        <TableCell style={{width: '15%', textAlign: 'left' }}>{moment(row.attributes.entry_date).format('D MMM YYYY')}</TableCell>
                                        <TableCell style={{width: '25%',textAlign: 'left' }}>
                                            <div style={{display: 'flex', justifyItems: 'center', justifyContent: 'flex-start' }}>
                                                <p data-test-id={`view${i}`} id='view-text' className="view"><Link to = {`/details?id=${row.id}&date=${row.attributes.entry_date}&clientId=${this.getUrlParameter('clientId')}&folderType=${this.getUrlParameter('folderType')}`}>View</Link></p>
                                                <img data-test-id={`download${i}`} src={download} style={{height: '24px', cursor: 'pointer', marginLeft: "20px", marginRight: "5px"}}/>
                                                <img data-testid="delete-data" src={deleteFile} onClick={() => this.showDeleteModel(row.attributes.id, row.attributes.title)} style={{height: '24px', cursor: 'pointer', marginLeft: "5px"}}/>
                                            </div>
                                            </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                        <div style={{padding: '30px'}}>
                                            <img src={noData} style={{height: '88px', width: '87px', marginBottom: '10px'}} />
                                            <h3>No document added yet</h3>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
				</div>
                
              
                {this.renderModalsDelete()}
			</PageWrapper>
		);
	}
}

export default ClientRehabilitation;
// Customizable Area End